import { formDataSerializer } from '../schema/bodySerializer'
import { client as apiClient } from '../schema/client'
import { HospitalsResponse, LoginData, LoginReturnData, User } from './types'
import { getAuthHeader } from '../schema/getAuthHeader'
import { components } from '../schema'
import { AddUserHospital } from '../pages/users/types'

export const loginUser = async (data: LoginData): Promise<LoginReturnData> => {
  const response = await apiClient.POST('/auth/login', {
    body: data,
    bodySerializer: formDataSerializer,
  })

  return {
    token: response.data?.access_token,
    error: response.error?.detail as LoginReturnData['error'],
  }
}

export const getUserInfo = async (token: string | undefined): Promise<{ userInfo: User; error: unknown }> => {
  const { data, error } = await apiClient.GET('/users/me', {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return { userInfo: data as User, error }
}

export const getHospitals = async (page?: number, size?: number): Promise<HospitalsResponse> => {
  const { data, error } = await apiClient.GET('/hospitals/', {
    headers: getAuthHeader(),
    params: {
      query: {
        page,
        size,
      },
    },
  })

  if (error) throw error

  return data
}

export const addUserToHospital = async (
  hospital_id: string,
  user_id: string,
  position: AddUserHospital,
): Promise<components['schemas']['SuccessResponse']> => {
  const { data, error } = await apiClient.POST('/hospitals/{hospital_id}/users/{user_id}', {
    headers: getAuthHeader(),
    params: {
      query: {
        position: position,
      },
      path: {
        hospital_id,
        user_id,
      },
    },
  })

  if (error) throw error

  return data
}
