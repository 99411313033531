import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import sha1 from 'sha1'

import { adminClient, client } from '../schema/client'
import { getAuthHeader } from '../schema/getAuthHeader'

import { getUserInfo, loginUser } from './api'

import { useResetStore } from '../utils/useRestoreStore'
import { useRouter } from 'vue-router'
import { LoginData, LoginReturnData, RegisterRequestDTO, ResetPasswordResponse, User, UserCreate } from './types'

export const useAuthStore = defineStore(
  'auth',
  () => {
    const user = ref<User>()
    const token = ref<string>()
    const isAuth = computed(() => !!user.value)
    const isLogOutAllowed = ref<boolean>(true)
    const isLogOutNeeded = ref<boolean>(false)
    const router = useRouter()

    const login = async (data: LoginData): Promise<LoginReturnData['error']> => {
      const response = await loginUser(data)
      token.value = response.token
      const userInfo = (await getUserInfo(token.value)).userInfo
      if (userInfo.role !== 'audit') {
        await logout()
        return {}
      }

      user.value = userInfo
      return response?.error
    }

    const logout = async (): Promise<void> => {
      await client.POST('/auth/logout', { headers: getAuthHeader() })
      token.value = undefined
      user.value = undefined

      const resetStore = useResetStore()
      if (resetStore.all) resetStore.all()

      router.push({ name: 'login' })
    }

    const resetPassword = async (
      email: string,
      new_password: string,
      user_id: string,
    ): Promise<ResetPasswordResponse> => {
      return await adminClient.POST('/auth/reset-password', {
        headers: getAuthHeader(),
        body: { email, new_password, secret: sha1(user_id) },
      })
    }

    const setIsLogOutAllowed = (value: boolean): void => {
      isLogOutAllowed.value = value
    }

    const setIsLogOutNeeded = (value: boolean): void => {
      isLogOutNeeded.value = value
    }

    const validateToken = async (): Promise<boolean> => {
      const { error, userInfo } = await getUserInfo(token.value)
      if (error) {
        token.value = undefined
        user.value = undefined
        return false
      }
      user.value = userInfo
      return true
    }

    const createUser = async (user: UserCreate): Promise<RegisterRequestDTO> => {
      const newUser: UserCreate = {
        email: user.email,
        name: user.name,
        password: user.password,
        role: user.role,
        is_active: user.is_active,
        is_superuser: user.is_superuser,
        is_verified: user.is_verified,
        phone: user.phone,
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-expect-error
      return await client.POST(`/auth/register`, {
        headers: getAuthHeader(),
        body: newUser,
      })
    }

    return {
      user,
      token,
      isAuth,
      isLogOutAllowed,
      isLogOutNeeded,
      login,
      logout,
      createUser,
      setIsLogOutAllowed,
      setIsLogOutNeeded,
      validateToken,
      resetPassword,
    }
  },
  {
    persist: {
      key: 'admin-auth',
      paths: ['token'],
    },
  },
)
